<template>
  <section v-if="files">

      <b-list-group class="mt-2">        
        <b-list-group-item v-for="(item, index) in sendAttachments" :key="index"><i class="fal fa-file"></i> {{ item.name }} </b-list-group-item>
      </b-list-group>

      <div class="mt-2">{{ $t("FROM") }}</div>
      <b-form-select name="selected_from" class="mt-2" v-model="selected_from" :options="from_list"></b-form-select>

      <div class="mt-2 ml-2">
        <img class="icon" src="/img/message_type/message.png" :alt="$t('MESSAGES.TYPE.message')"  /> {{ boxInformation.name }}, {{  boxInformation.email }}
        <span v-if="boxInformation.fax">, <img class="icon" src="/img/message_type/fax-message.png" :alt="$t('MESSAGES.TYPE.fax-message')"  /> {{ boxInformation.fax }}</span>
        <span v-if="boxInformation.sdk">, <img class="icon" src="/img/message_type/sdk-message.png" :alt="$t('MESSAGES.TYPE.sdk-message')"  /> {{ boxInformation.sdk }}</span>
      </div>
      
      <div class="mt-2">{{ $t("SEND_AS") }}</div>

      <b-list-group>
      <b-list-group-item href="#" @click="showSefos"
      ><img class="icon mr-2" src="/img/message_type/message.png" :alt="$t('MESSAGES.TYPE.message')"  /> {{ $t("MESSAGES.TYPE.message") }}</b-list-group-item
    >
    <b-list-group-item v-if="sdkVisible" href="#" @click="showSDK"
      ><img class="icon mr-2" src="/img/message_type/sdk-message.png" :alt="$t('MESSAGES.TYPE.sdk-message')"  /> {{ $t("MESSAGES.TYPE.sdk-message") }}
      </b-list-group-item
    >
      <b-list-group-item v-if="faxVisible" href="#" @click="showFax" :disabled="!allowdFaxFiles"
      >      
        <img class="icon mr-2" src="/img/message_type/fax-message.png" :alt="$t('MESSAGES.TYPE.fax-message')"  /> {{ $t("MESSAGES.TYPE.fax-message") }}
        <div class="error-text" v-if="!allowdFaxFiles">
          {{  $t('ERROR.ONLY_PDF_DOC_ALLOWED') }}
        </div>
      </b-list-group-item
      ></b-list-group>
      
    <AddSefosMessage
      :show="showSefosMessage"
      :user_uuid="selected_from.user_uuid"
      :functionbox_uuid="selected_from.functionbox_uuid"
      :attachments="sendAttachments"
      @ChangeShow="changeShowSefosMessage"
      @SentMessage="SentMessage"
    ></AddSefosMessage>
    <AddSDKMessage
      v-if="sdkVisible"
      :show="showSdkMessage"
      :functionbox_uuid="selected_from.functionbox_uuid"
      :attachments="sendAttachments"
      @ChangeShow="changeShowSdkMessage"
      @SentMessage="SentMessage"
    ></AddSDKMessage>
    <AddFaxMessage
      :show="showFaxMessage"
      :attachments="sendAttachments"
      :functionbox_uuid="selected_from.functionbox_uuid"
      @ChangeShow="changeShowFaxMessage"
      @SentMessage="SentMessage"
    ></AddFaxMessage>
  </section>
</template>
<script>
import AddSefosMessage from "./Message/SefosMessage";
import AddSDKMessage from "./Message/SDKMessage";
import AddFaxMessage from "./Message/FaxMessage";
export default {
  props: {
    user_uuid: {
      default: "",
      type: String
    },
    files: {
      default: () => [],
      type: Array
    }
  },
  components: {
    AddSefosMessage,
    AddSDKMessage,
    AddFaxMessage,
  },
  data() {
    return {
      selected_from: {
        functionbox_uuid: "",
        user_uuid: this.user_uuid
      },
      from_list: [],
      showSefosMessage: false,
      showSdkMessage: false,
      showFaxMessage: false
    };
  },
  methods: {
    changeShowSefosMessage(val) {
      this.showSefosMessage = val;
    },
    changeShowSdkMessage(val) {
      this.showSdkMessage = val;
    },
    changeShowFaxMessage(val) {
      this.showFaxMessage = val;
    },
    showSefos() {
      this.showSefosMessage = true;
    },
    showFax() {
      this.showFaxMessage = true;
    },
    showSDK() {
      this.showSdkMessage = true;
    },
    SentMessage() {
      this.showSefosMessage = false;
      this.showSdkMessage = false;
      this.showFaxMessage = false;
      this.$emit("Changed");
    },
  },
  computed: {    
    faxVisible() {
      if (this.selected_from.functionbox_uuid != "") {
        let self = this;
        let fb = this.user.functionboxes.items.filter(function (item) {
          return item.functionbox_uuid == self.selected_from.functionbox_uuid;
        });
        if (fb.length == 0) {
          return false;
        } else {
          if (fb[0].fax_enabled != undefined) {
            return fb[0].fax_enabled;
          }
        }
      }
      return false;
    },
    sdkVisible() {
      if (this.user.ticket.sdk_enabled) {
        if (this.selected_from.functionbox_uuid != "") {
          let self = this;
          let fb = this.user.functionboxes.items.filter(function (item) {
            return item.functionbox_uuid == self.selected_from.functionbox_uuid;
          });
          if (fb.length == 0) {
            return false;
          } else {
            if (fb[0].sdk_enabled != undefined) {
              return fb[0].sdk_enabled;
            }
          }
        }
      }
      return false;
    },
    sendAttachments(){
      return this.files.map(
        function(item) {
          return {
            attachment_uuid: "",
            name: item.name,
            type: item.mimetype + "",
            location: item.location + "",
            size: item.size,
            status: "NOT_UPLOADED"
          };
        }
      );
    },
    allowdFaxFiles(){
      let allowedExtensions = ['.pdf', '.doc', '.docx'];
      return this.files.some(function(item) {
        return allowedExtensions.some(ext => item.name.toLowerCase().endsWith(ext))
      });
    },
    boxInformation(){
      let boxInformationObj = {
        name: "",
        email: "",
        sdk: "",
        fax: ""
      }
      if(this.selected_from.user_uuid == "" && this.selected_from.functionbox_uuid == "")
      {
        boxInformationObj.name = this.user.information.name;
        boxInformationObj.email = this.user.information.email;
      }else{
        if(this.user_uuid != "")
        {
          for (let ix = 0; ix < this.user.delegatedboxes.items.length; ix++) {
            if (this.user.delegatedboxes.items[ix].user_uuid == this.selected_from.user_uuid) {
              boxInformationObj.name = this.user.delegatedboxes.items[ix].name;
              boxInformationObj.email = this.user.delegatedboxes.items[ix].email;              
            }
          }
        }else{
          if(this.functionbox_uuid != "")
          {
            for (let ix = 0; ix < this.user.functionboxes.items.length; ix++) {
              if (this.user.functionboxes.items[ix].functionbox_uuid == this.selected_from.functionbox_uuid) {
                boxInformationObj.name = this.user.functionboxes.items[ix].name;
                boxInformationObj.email = this.user.functionboxes.items[ix].email;              
                if(this.user.functionboxes.items[ix].sdk_identifier != undefined)
                {
                  boxInformationObj.sdk = this.user.functionboxes.items[ix].sdk_identifier;              
                }
                if(this.user.functionboxes.items[ix].fax_identifier != undefined)
                {
                  boxInformationObj.fax = this.user.functionboxes.items[ix].fax_identifier;              
                }
              }
            }                   
          }
        }        
      }
      return boxInformationObj;
    },
  },
  mounted() {    
    this.from_list.push({
      value: {
          functionbox_uuid: "",
          user_uuid: ""
        },
      text: this.user.information.email,
    });         
    if(this.user.functionboxes.items != undefined)
    {
      for (let ix = 0; ix < this.user.functionboxes.items.length; ix++) {
        let item = {
          functionbox_uuid: this.user.functionboxes.items[ix].functionbox_uuid,
          user_uuid: ""
        };
        this.from_list.push({
          value: item,
          text: this.user.functionboxes.items[ix].name + ' - ' + this.user.functionboxes.items[ix].email + "",
        });
      }
    }        
    if(this.user.delegatedboxes.items != undefined)
    {
      for (let ix = 0; ix < this.user.delegatedboxes.items.length; ix++) {
        let item = {
          functionbox_uuid: "",
          user_uuid: this.user.delegatedboxes.items[ix].user_uuid
        };
        this.from_list.push({
          value: item,
          text: this.user.delegatedboxes.items[ix].name + ' - ' + this.user.delegatedboxes.items[ix].email + "",
        });
      }
    }
  },
};
</script>
