<template>
  <section v-if="user.information && loaded">
    <div v-if="!filestorage_enabled">
      <div class="center-information">
        <div class="center-information-content">
          <i class="fa-light fa-2x fa-file-slash"></i>
          <br /><br />
          {{ $t("NO_FILESTORAGE_SERVICE") }}
        </div>
      </div>
    </div>
    <div
      v-if="filestorage_enabled"
      id="wrapper"
      v-bind:class="{ showFolders: showFolders }"
      :key="$route.fullPath"
    >
     <div id="inbox-sidebar" class="h-100">
        <i
          class="fal fa-chevron-right nav-icon clickable"
          v-show="!showFolders"
          @click="showFolders = true"
        ></i>
        <i
          class="fal fa-chevron-left nav-icon clickable"
          v-show="showFolders"
          @click="showFolders = false"
        ></i>
        <div class="mt-2 smallFolders" v-show="!showFolders">
          <b-nav vertical tabs class="mt-2 inbox-nav">
            <b-nav-text>
              <b-avatar size="30px"><i class="fal fa-user-alt"></i></b-avatar>
            </b-nav-text>
            <b-nav-item
              v-b-tooltip.hover
              :title="$t('FILESTORAGE.FILES')"
              :to="{ name: 'FileStorage.Files', params: { parent_uuid: null, section: 'FILES' } }"
              @click="changedFolder('')"
            >
              <i class="fal fa-folders"></i
              ><span class="sr-only">{{ $t("FILESTORAGE.FILES") }}</span>
            </b-nav-item>
            
              <b-nav-item
              v-for="(item, index) in applications"
              :key="'application_'+ index">
              v-b-tooltip.hover
                :to="{
                  name: 'FileStorage.Application',
                  params: { folder_uuid: item.folder_uuid, section: 'APPLICATION' },
                }"
                @click="changedFolder('')"
              >
                <i class="fal fa-folders"></i>&nbsp;&nbsp;{{
                  item.name
                }}               
          <b-badge v-if="item.not_listed != 0">{{
            item.not_listed
          }}</b-badge>
              </b-nav-item>

          </b-nav>
        </div>

        <div class="mt-2" v-show="showFolders">
          <b-nav vertical tabs class="mt-2 inbox-nav">
            <div class="email-text">
              <b-nav-text class="mb-0 pb-0">
                &nbsp;<b-avatar size="30px" variant="secondary" class="mb-1"
                  ><i class="fal fa-folders"></i
                ></b-avatar>
                {{ user.information.email }}
              </b-nav-text>
            </div>


            <div class="email-text">
              <b-nav-item
                v-b-tooltip.hover
                :to="{
                  name: 'FileStorage.Files',
                  params: { folder_uuid: null, section: 'FILES' },
                }"
                @click="changedFolder('')"
              >
                <i class="fal fa-folders"></i>&nbsp;&nbsp;{{
                  $t("FILESTORAGE.FILES")
                }}
              </b-nav-item>
            </div>

            <div class="email-text"
                v-for="(item, index) in applications"
                :key="'application_'+ index">
              <b-nav-item
                v-b-tooltip.hover
                :to="{
                  name: 'FileStorage.Application',
                  params: { folder_uuid: item.folder_uuid, section: 'APPLICATION' },
                }"
                @click="changedFolder('')"
              >
                <i class="fal fa-folders"></i>&nbsp;&nbsp;{{
                  item.name
                }}           
                <b-badge v-if="item.not_listed != 0">{{
                  item.not_listed
                }}</b-badge>
              </b-nav-item>
            </div>

          </b-nav>
        </div>
      </div>
      <div id="inbox-content">
        <div class="w-100">
          <b-form inline :key="'folder_uuid_' + folder_uuid">

            <b-button @click="refreshList" class="no-border"
              ><i class="fal fa-sync"></i
              ><span class="sr-only">{{ $t("REFRESH") }}</span></b-button
            >

            <b-dropdown variant="dark" v-if="section == 'FILES'">
              <template #button-content>
                <i class="fal fa-plus"></i> {{ $t("ADD") }}
              </template>
              <b-dropdown-item
                :title="$t('FILESTORAGE.ADD_FOLDER')"
                href="#"
                @click="show_add_folder = true"
                ><i class="fal fa-folder-plus"></i>
                {{ $t("FILESTORAGE.ADD_FOLDER") }}</b-dropdown-item
              >
              <b-dropdown-item
                :title="$t('FILESTORAGE.ADD_NOTE')"
                v-if="folder_uuid"
                href="#"
                @click="show_add_note = true"
                ><i class="fal fa-file-alt"></i>
                {{ $t("FILESTORAGE.ADD_NOTE") }}</b-dropdown-item
              >
              <b-dropdown-item
                :title="$t('FILESTORAGE.ADD_FILES')"
                v-if="folder_uuid"
                href="#"
                @click="show_add_file = true"
                ><i class="fal fa-file-plus"></i>
                {{ $t("FILESTORAGE.ADD_FILES") }}</b-dropdown-item
              >
            </b-dropdown>
            <label class="sr-only" for="inline-form-input-search">{{
              $t("LIMIT")
            }}</label>

              <b-form-input trim
                id="inline-form-input-search"
                class="i-100 searchText hidden-mobile ml-2"
                v-model="searchText"
                :placeholder="$t('SEARCH')"
                @keydown.enter.prevent
              ></b-form-input>

              <b-button
                v-if="folder_uuid && IsAdmin && section == 'FILES'"
                class="no-border ml-2"
                @click="folderUpdate(folder_uuid)"
              >
                <i class="fal fa-cog"></i>
              </b-button>

              <div class="hidden-not-mobile w-100 mt-2 ml-2">
                <b-form inline>
                  <label class="sr-only" for="inline-form-input-search">{{$t('SEARCH')}}</label>
                  <b-form-input trim
                    class="ml-2 i-100 searchText"
                    v-model="searchText"
                    :placeholder="$t('SEARCH')"
                    @keydown.enter.prevent
                  ></b-form-input>
                </b-form>
              </div>
          </b-form>

          <router-view
            :key="redrawListKey"
            :searchText.sync="searchText"
            :parent_uuid="folder_uuid"
            :user_uuid="user_uuid"
            :limit.sync="limit"
            :is_admin="IsAdmin"
            @changeSection="changeSection"
            @fileInfo="fileInfo"
            @fileUpdate="fileUpdate"
            @changedFolder="changedFolder"
            @folderUpdate="folderUpdate"
            @folderUsers="folderUsers"
            @folderMove="folderMove"
            @fileNewVersion="fileNewVersion"
            @fileVersions="fileVersions"
            @fileMove="fileMove"
            @fileSendAs="fileSendAs"
            @setCrumbs="setCrumbs"
          ></router-view>
        </div>
      </div>
    </div>

    <b-sidebar
      id="update-folder-sidebar"
      :title="$t('FILESTORAGE.UPDATE_FOLDER')"
      no-header-close
      sidebar-class="big_sidebar"
      lazy
      right
      backdrop
      no-slide
      shadow
      v-model="show_update_folder"
    >
      <div
        class="view-content create-message px-3 py-2"
        v-if="selected_folder_uuid"
      >
        <UpdateFolder
          @updatedInfo="updatedInfo"
          @removedFolder="removedFolder"
          @closeUpdateFolder="closeUpdateFolder"
          :user_uuid="user_uuid"
          :start_tab="updateFolderTab"
          :folder_uuid="selected_folder_uuid"
        ></UpdateFolder>
      </div>
    </b-sidebar>

    <b-sidebar
      id="add-folder-sidebar"
      :title="$t('FILESTORAGE.ADD_FOLDER')"
      no-header-close
      sidebar-class="big_sidebar"
      right
      lazy
      backdrop
      no-slide
      shadow
      v-model="show_add_folder"
    >
      <div class="view-content create-message px-3 py-2">
        <AddFolder
          @addedFolder="addedFolder"
          @closeAddFolder="closeAddFolder"
          :folder_uuid="folder_uuid"
          :user_uuid="user_uuid"
        ></AddFolder>
      </div>
    </b-sidebar>

    <b-sidebar
      id="add-file-sidebar"
      :title="$t('FILESTORAGE.ADD_FILES')"
      no-header-close
      sidebar-class="big_sidebar"
      right
      lazy
      backdrop
      no-slide
      shadow
      v-model="show_add_file"
    >
      <div class="view-content create-message px-3 py-2">
        <AddFile
          @addedFile="addedFile"
          @closeAddFile="closeAddFile"
          :folder_uuid="folder_uuid"
          :user_uuid="user_uuid"
        ></AddFile>
      </div>
    </b-sidebar>

    <b-sidebar
      id="update-file-sidebar"
      :title="$t('FILESTORAGE.UPDATE_FILE')"
      no-header-close
      sidebar-class="big_sidebar"
      right
      lazy
      backdrop
      no-slide
      shadow
      v-model="show_update_file"
    >
      <div class="view-content create-message px-3 py-2">
        <UpdateFile
          @updatedInfo="updatedInfo"
          @removedFile="removedFile"
          @closeUpdateFile="closeUpdateFile"
          :section="section"
          :folder_uuid="folder_uuid"
          :start_tab="updateFileTab"
          :file_uuid="selected_file_uuid"
          :user_uuid="user_uuid"
        ></UpdateFile>
      </div>
    </b-sidebar>

    <b-sidebar
      id="add-note-sidebar"
      :title="$t('FILESTORAGE.ADD_NOTE')"
      no-header-close
      sidebar-class="big_sidebar"
      right
      lazy
      backdrop
      no-slide
      shadow
      v-model="show_add_note"
    >
      <div class="view-content create-message px-3 py-2">
        <AddNote
          @addedNote="addedNote"
          @closeAddNote="closeAddNote"
          :folder_uuid="folder_uuid"
          :user_uuid="user_uuid"
        ></AddNote>
      </div>
    </b-sidebar>

    <b-modal
      lazy
      :dialog-class="teams.theme"
      v-model="show_send_message"
      ok-variant="secondary"
      ok-only
      :ok-title="$t('CLOSE')"
      hide-header
      id="modal-send-as-message"
    >
      <SendAsMessage
        v-if="files.length != 0"
        :files="files"
        @SentMessage="SentMessage"
      ></SendAsMessage>
    </b-modal>
  </section>
</template>
<script>
import SendAsMessage from "@/components/Compose/SendAsMessage";
import AddFolder from "@/components/FileStorage/AddFolder";
import AddFile from "@/components/FileStorage/AddFile";
import AddNote from "@/components/FileStorage/AddNote";
import UpdateFile from "@/components/FileStorage/File/Page";
import UpdateFolder from "@/components/FileStorage/Folder/Page";
import mobileDetect from "mobile-device-detect";
export default {
  props: {
    user_uuid: {
      default: "",
      type: String,
    }
  },
  components: {
    AddNote,
    AddFile,
    AddFolder,
    UpdateFolder,
    UpdateFile,
    SendAsMessage,
  },
  data() {
    return {
      section: 'FILES',
      folder_uuid: "",
      updateFolderTab: 0,
      updateFileTab: 0,
      selected_folder_uuid: "",
      selected_file_uuid: "",
      show_update_file: false,
      show_add_note: false,
      show_add_file: false,
      show_add_folder: false,
      show_update_folder: false,
      show_send_message: false,
      crumbs: [],
      device: mobileDetect,
      showFolders: false,
      searchText: "",
      limit: 45,
      information: null,
      redrawListIndex: 0,
      file_uuid: "",
      loading: false,
      currentParent: null,
      folderInformation: null,
      files: [],
      applications: [],
      filestorage_enabled: true,
      loaded: false,
    };
  },
  methods: {
    changeSection(section)
    {
      this.section = section;
    },
    async checkEnabled() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/filestorage/enabled")
        .then(function (result) {
          self.filestorage_enabled = result.data;
          self.loaded = true;
          self.getApplicationFolders();
        })
        .catch(function () {
          self.filestorage_enabled = false;
          self.loaded = true;
        });
    },
    delegatedKey(index) {
      return "delegated_" + index;
    },
    fileSendAs(files) {
      this.show_send_message = true;
      this.files = files;
    },
    SentMessage() {
      this.files = [];
      this.show_send_message = false;
    },
    fileNewVersion(file_uuid) {
      this.selected_file_uuid = file_uuid;
      this.updateFileTab = 1;
      this.show_update_file = true;
    },
    fileVersions(file_uuid) {
      this.selected_file_uuid = file_uuid;
      this.updateFileTab = 2;
      this.show_update_file = true;
    },
    fileMove(file_uuid) {
      this.selected_file_uuid = file_uuid;
      this.updateFileTab = 4;
      this.show_update_file = true;
    },
    fileInfo(file_uuid) {
      this.selected_file_uuid = file_uuid;
      this.updateFileTab = 3;
      this.show_update_file = true;
    },
    fileUpdate(fileinfo) {
      this.selected_file_uuid = fileinfo.file_uuid;
      this.updateFileTab = fileinfo.tab;
      this.show_update_file = true;
    },
    folderUpdate(folder_uuid) {
      this.selected_folder_uuid = folder_uuid;
      this.updateFolderTab = 0;
      this.show_update_folder = true;
    },
    folderMove(folder_uuid) {
      this.selected_folder_uuid = folder_uuid;
      this.updateFolderTab = 1;
      this.show_update_folder = true;
    },
    folderUsers(folder_uuid) {
      this.selected_folder_uuid = folder_uuid;
      this.updateFolderTab = 2;
      this.show_update_folder = true;
    },
    GetFolderInformation: function () {
      let self = this;
      self.loading = true;
      let GetFolderRequestDTO = {
        user_uuid: this.user_uuid,
        folder_uuid: this.folder_uuid,
      };
      this.$http
        .post(
          this.user.hostname + "/filestorage/folder/info",
          GetFolderRequestDTO
        )
        .then((response) => {
          self.loading = false;
          self.information = response.data;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getApplicationFolders: function () {
      let self = this;
      this.loading = true;
      let ListFoldersRequestDTO = {
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        user_uuid: this.user_uuid,
        parent_uuid: "",
        section: "APPLICATION",
        search_text: "",
        page: 1,
        limit: 2000,
      };
      this.$http
        .post(
          this.user.hostname + "/filestorage/folder/list",
          ListFoldersRequestDTO
        )
        .then((response) => {
          //self.redrawIndex = self.redrawIndex + 1;
          self.applications = response.data.items.map((element) => {
            element.type = "folder";
            return element;
          });
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    changedFolder(folder_uuid) {
      this.folder_uuid = folder_uuid;
      this.files = [];
      this.information = null;
      if(folder_uuid != "")
      {
        this.GetFolderInformation();
      }
      this.getApplicationFolders();
    },
    updatedInfo() {
      this.refreshList();
    },
    removedFile() {
      this.selected_file_uuid = "";
      this.updateFileTab = 0;
      this.show_update_file = false;
      this.refreshList();
    },
    removedFolder() {
      this.selected_folder_uuid = "";
      this.updateFolderTab = 0;
      this.show_update_folder = false;
      this.refreshList();
    },
    closeUpdateFolder() {
      this.show_update_folder = false;
      this.selected_folder_uuid = "";
    },
    closeUpdateFile() {
      this.show_update_file = false;
      this.selected_file_uuid = "";
    },
    closeAddFolder() {
      this.show_add_folder = false;
    },
    closeAddFile() {
      this.show_add_file = false;
    },
    closeAddNote() {
      this.show_add_note = false;
    },
    addedNote() {
      this.show_add_note = false;
      this.refreshList();
    },
    addedFile() {
      this.show_add_file = false;
      this.refreshList();
    },
    addedFolder() {
      this.show_add_folder = false;
      this.refreshList();
    },
    setCrumbs(crumbs) {
      this.crumbs = crumbs;
    },
    resetFolder() {
      this.changedFolder("");
    },
    refreshList() {
      this.redrawListIndex++;
    },
  },
  computed: {
    IsAdmin()
    {
      if(this.information == null)
      {
        return false;
      }else{
        if(this.information.access_items != undefined)
        {
          let self = this;
          return this.information.access_items.filter(function (item) {
            if(self.user_uuid != "")
            {
              return (self.user_uuid == item.user_uuid && item.is_admin == 1);
            }else{
              return (self.user.information.user_uuid == item.user_uuid && item.is_admin == 1);
            }}).length != 0;
        }else{
          return false;
        }
      }
    },
    redrawListKey() {
      return "redrawList_" + this.redrawListIndex;
    },
  },
  mounted() {
    this.checkEnabled();
    this.showFolders = !this.device.isMobile;
  },
};
</script>
<style></style>
